import React from "react"
import {navigate} from "gatsby"
import {Link} from "gatsby"
import {BrandLogin, dashboardPath, isBrandLoggedin} from "../services/auth"

class BrandLoginForm extends React.Component {
    state = {
        email: ``, password: ``, error: ``
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = async event => {
        event.preventDefault()
        this.setState({error: false});
        var lgres = await BrandLogin(this.state)
        if (lgres === false) {
            //console.log("setting err");
            this.setState((state, props) => {
                return {email: state.email, password: "", error: "Invalid Login Credentials."};
            });
        } else {
            const redirect = window.localStorage.getItem('redirectUrl');
            if (redirect !== null) {
                window.localStorage.removeItem('redirectUrl')
                navigate(redirect)
            } else {
                navigate(dashboardPath())
            }
        }
    }

    render() {
        if (isBrandLoggedin()) {
            navigate('/brand-home')
        }
        return (<>
            <h1 className="text-center">Brand Log in</h1>
            {this.state.error !== "" && <div className="err">{this.state.error}</div>}
            <form method="post" onSubmit={this.handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input type="text" name="email" className="form-control" onChange={this.handleUpdate}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" name="password" className="form-control" onChange={this.handleUpdate}/>
                    <p className="text-left"><Link to="/forget-password/brand">Forgot Password?</Link></p>
                </div>
                <div className="mb-3 text-center">
                    <input type="submit" className="btn btn-primary" value="Log In"/>
                </div>
            </form>
        </>)
    }
}

export default BrandLoginForm