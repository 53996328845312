import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import BrandLoginForm from "../components/brandloginform";
import AruzySeo from "../components/aruzySeo";

const BrandLoginPage = () => {
	
	//TODO:: Redirect user to myaccount page if they are already logged in
	return (
		<main>
			<Header/>
			<AruzySeo title={"Brand Login"}/>
				<div className="container pt-60 mb-30">
					<div className="row justify-content-center">
						<div className="col-xs-12 col-md-4  ">
							<BrandLoginForm/>
						</div>
					</div>
				</div>
			<Footer/>
		</main>
	)
}

export default BrandLoginPage